import { useAxios } from '~/src/Infrastructure/Axios/Axios'
import { Order } from '~/src/Model/Order/Order'
import { DriverLocation } from '~/src/Model/Order/DriverLocation'
import { AxiosResponse } from 'axios'
import {OrderEvaluationRequestData} from '~/src/Model/Order/OrderEvaluation'

export const useOrderRepository = () => {
	const { $axios } = useAxios()

	return {
		loadOrder: (trackingCode: string): Promise<AxiosResponse<Order>> => $axios.get<Order>(`/order/${trackingCode}`),
		loadDriverLocation: (trackingCode: string): Promise<AxiosResponse<DriverLocation>> => $axios.get<DriverLocation>(`/driver-location/${trackingCode}`),
		sendEvaluation: (trackingCode: string, request: OrderEvaluationRequestData): Promise<AxiosResponse<void>> => $axios.post<void>(`/evaluation/${trackingCode}`, request)
	}
}
