// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import {useEffect, useRef, useState} from 'react'
import {useMapBoxRepository} from '~/src/Infrastructure/Mapbox/MapboxRepository'
import {OrderData, OrderState} from '~/src/Model/Order/Order'
import {useMutation} from '@tanstack/react-query'
import {MapboxCoordinates, MapboxDirection} from '~/src/Model/Mapbox/Mapbox'
import {useOrderRepository} from '~/src/Infrastructure/Order/OrderRepository'
import {DriverLocation} from '~/src/Model/Order/DriverLocation'
import {useInterval} from '~/src/Infrastructure/Interval/Interval'
import HouseIcon from '~/src/Static/Images/house-door.svg'
import CarIcon from '~/src/Static/Images/car.svg'

export const useMapbox = (mapContainer: React.MutableRefObject<null>, order: OrderData, trackingCode: string) => {
    const {getDirection} = useMapBoxRepository()
    const { loadDriverLocation } = useOrderRepository()
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN as string;
    const map = useRef<mapboxgl.Map | null>(null);

    const [driverCoords, setDriverCoords] = useState<MapboxCoordinates>([Number(order.branch.lng), Number(order.branch.lat)])

    const [startCoords, setStartCoords] = useState<MapboxCoordinates>([Number(order.branch.lng), Number(order.branch.lat)])
    const [endCoords, setEndCoords] = useState<MapboxCoordinates>([Number(order.customer.lng), Number(order.customer.lat)])
    const [estimatedTime, setEstimatedTime] = useState<number>(0)

    const directionMutation = useMutation<MapboxDirection>(async () => {
        const response = await getDirection(startCoords, endCoords)

        return response.data
    })

    const courierPositionMutation = useMutation<DriverLocation>(async () => {
        const response = await loadDriverLocation(trackingCode)

        return response.data
    })


    const createStartPoint = () => {
        const geoJson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Point',
                        coordinates: startCoords
                    }
                }
            ]
        }

        document.querySelectorAll('.marker-car').forEach(el => el.parentNode?.removeChild(el))

        const houseIconStates = [
            OrderState.NEW,
            OrderState.PICKUP,
        ]

        const el = document.createElement('div');
        const width = 40
        const height = 40
        el.className = 'marker-car';
        el.style.backgroundImage = `url(${houseIconStates.includes(order.state) ? HouseIcon : CarIcon})`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';

        new mapboxgl.Marker(el).setLngLat(geoJson.features[0].geometry.coordinates).addTo(map.current)
    }

    const fitBounds = () => {
        var bounds = new mapboxgl.LngLatBounds();

        bounds.extend(startCoords);
        bounds.extend(endCoords);

        map.current?.fitBounds(bounds, { padding: 30 });
    }

    const createEndPoint = () => {
        const geoJson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Point',
                        coordinates: endCoords
                    }
                }
            ]
        }

        document.querySelectorAll('.marker-customer').forEach(el => el.parentNode?.removeChild(el))

        const el = document.createElement('div');
        const width = 24
        const height = 24
        el.className = 'marker-customer';
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';
        el.style.backgroundColor = order.branch.color
        el.style.borderRadius = "100%"

        new mapboxgl.Marker(el).setLngLat(geoJson.features[0].geometry.coordinates).addTo(map.current)
    }

    const createRoute = () => {
        const geoJson = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: directionMutation.data?.routes[0]?.geometry.coordinates
            }
        }
        if (map.current?.getSource('route')) {
            // @ts-ignore
            map.current?.getSource('route').setData(geoJson)
            return
        }
        map.current?.addLayer({
            id: 'route',
            type: 'line',
            source: {
                type: 'geojson',
                // @ts-ignore
                data: geoJson
            },
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': '#3887be',
                'line-width': 5,
                'line-opacity': 0.75
            }
        });
        fitBounds()
    }

    useEffect(() => {
        const processMap = async () => {
            await directionMutation.mutate()
        }
        if (order.state !== OrderState.DELIVERED) {
            processMap()
        }

        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current!,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [(Number(order.branch.lng) + Number(order.customer.lng)) / 2, (Number(order.branch.lat) + Number(order.customer.lat)) / 2],
                zoom: 12
            });
        }

        map.current?.on('load', async () => {
            if (order.state === OrderState.DELIVERING) {
                await courierPositionMutation.mutate()
            } else {
                createStartPoint()
            }
            createEndPoint()
            setMapLoaded(true)
        })
    }, [])

    useInterval(async () => {
        if (order.state !== OrderState.DELIVERING) {
            return
        }
        await courierPositionMutation.mutate()
    }, 20000)

    useEffect(() => {
        if (order.state === OrderState.DELIVERING) {
            courierPositionMutation.mutate()
        }
    }, [order.state])

    useEffect(() => {
        if (mapLoaded && directionMutation.data) {
            createStartPoint()
            createRoute()
            if (order.state === OrderState.DELIVERING) {
                setEstimatedTime((directionMutation.data.routes[0].duration / 60))
            }
        }
    }, [mapLoaded, directionMutation.data])


    useEffect(() => {
        if (courierPositionMutation.data) {
            setDriverCoords([Number(courierPositionMutation.data?.data.lng), Number(courierPositionMutation.data?.data.lat)])
            setStartCoords([Number(courierPositionMutation.data?.data.lng), Number(courierPositionMutation.data?.data.lat)])
        }
    }, [courierPositionMutation.data])


    useEffect(() => {
        if (order.state !== OrderState.DELIVERING) {
            return
        }
        directionMutation.mutate()
        fitBounds()
    }, [driverCoords])

    return {
        estimatedTime
    }
}
