import {Col, Container} from 'react-bootstrap'

export default function Error404() {
	return (
		<>
			<Container className={"container-404"}>
				<div className="header-container">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				</div>
				<Col xl={6} lg={7} md={8} className={"mx-auto"}>
					<h1>Objednávka neexistuje, nebo vypršela její platnost</h1>
					<Col md={6} sm={6} xs={8}  className={"mx-auto mt-3"}>
						<a className="btn btn-primary" href="https://www.prorozvoz.cz">Zpět na hlavní web</a>
					</Col>
				</Col>
			</Container>
		</>
	)
}
