import Placeholder from 'react-bootstrap/Placeholder'

export default function OrderSummarySkeleton() {
	return (
		<>
			<Placeholder animation={'glow'} as={"div"}>
				<Placeholder xs={12} className={"order-summary-skeleton"} />
			</Placeholder>
		</>
	)
}
