import React from 'react'
import ReactDOM from 'react-dom/client'
import '~/src/Styles/styles.scss'
import '~/src/Styles/custom_styles.scss'
import { ConfigContextProvider } from '~/src/Infrastructure/WebConfigContext/ConfigContext'
import App from '~/src/App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Error404 from '~/src/Pages/Error404'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
	{
		path: '/:trackingCode',
		element: <App/>,
		errorElement: <Error404/>
	},
	{
		path: '*',
		element: <Error404/>
	}
])

const queryClient = new QueryClient()

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ConfigContextProvider>
				<RouterProvider router={router}/>
			</ConfigContextProvider>
		</QueryClientProvider>
	</React.StrictMode>
)

