import RatingForm from "~/src/Components/Rating/RatingForm";
import {useState} from "react";
import { Rating as RatingPlugin } from 'react-simple-star-rating'
import {RatingFormData} from "~/src/Model/Rating/RatingForm";
import { useOrderEvaluation } from '~/src/Infrastructure/Order/OrderEvaluation'
import { Alert } from 'react-bootstrap'
import { AxiosError } from 'axios'


interface Props {
    trackingCode: string
    color: string
    submitDisabled: boolean
}

export default function Rating (props: Props) {
    const [showForm, setShowForm] = useState<boolean>(false)
    const [rating, setRating] = useState<number>(4.5)
    const { send, error, isSuccess } = useOrderEvaluation()

    const handleClickRating = (rate: number) => {
        setRating(rate)
        setShowForm(true)
    }

    const onSubmitHandler = async (formData: RatingFormData) => {
        await send(props.trackingCode, {
            evaluation: rating,
            message: formData.note
        })
    }

    const createErrorAlert = (error: AxiosError) => {
        if (error?.response?.status === 409) {
            return <Alert variant={"danger"}>Objednávka již byla hodnocena</Alert>
        }
    }

    if (isSuccess) {
        return <h2>Objednávka ohodnocena, děkujeme</h2>
    }

    return (
        <div>
            <>
                <h2>Spokojenost s kurýrem</h2>
                <div className={"mt-2"}>
                    {error && createErrorAlert(error)}
                </div>
                    <div className="mr-24">
                        <RatingPlugin
                            SVGstorkeWidth={0.5}
                            SVGstrokeColor="#6F7174"
                            emptyColor="#ffffff"
                            fillColor={props.color}
                            initialValue={rating}
                            allowFraction
                            onClick={handleClickRating}
                        />
                    </div>
                <div className="col-xl-6 col-lg-7 col-md-6 col-sm-8 col-10 mt-3">
                    <div className="flex-grow-1">
                        {showForm && <RatingForm onSubmit={onSubmitHandler} color={props.color} submitDisabled={props.submitDisabled} /> }
                    </div>
                </div>
            </>
        </div>
    )
}
