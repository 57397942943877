import {useEffect, useRef} from 'react'
import { useMapbox } from '~/src/Infrastructure/Mapbox/Mapbox'
import {OrderData} from "~/src/Model/Order/Order";

interface Props {
    order: OrderData
    trackingCode: string
    onEstimatedTimeChange: (estimatedTime: number) => void
}

export default function Map(props: Props) {
    const mapContainer = useRef(null)
    const { estimatedTime } = useMapbox(mapContainer, props.order, props.trackingCode)

    useEffect(() => {
        if (estimatedTime > 0) {
            props.onEstimatedTimeChange(estimatedTime)
        }
    }, [estimatedTime])

    return (
        <div ref={mapContainer} className="map"></div>
    )
}
