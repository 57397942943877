import React, { useMemo, useState } from 'react'
import { useUuidGenerator } from '~/src/Infrastructure/Uuid/UuidGenerator'
import { InputType } from '~/src/Model/Input/InputType'
import classNames from 'classnames'
import { FieldErrors } from 'react-hook-form'

interface Props {
	name: string
	id?: string
	label?: string
	placeholder?: string
	optional?: boolean
	type?: InputType
	inputClassName?: string
	wrapperClassName?: string
	register?: any | null
	errors?: FieldErrors,
}

const defaultProps: Partial<Props> = {
	id: '',
	label: '',
	placeholder: '',
	inputClassName: '',
	wrapperClassName: '',
	register: null,
	optional: false
}

const TextInput = React.forwardRef(function TextInput(props: Props, ref) {
	const { generate } = useUuidGenerator()
	props = { ...defaultProps, ...props }
	const [id] = useState(props.id || generate())

	const sharedProps = useMemo(() => {
		return {
			id,
			name: props.name,
			placeholder: props.placeholder,
			className: classNames('form-control', props.inputClassName),
			...props.register
		}
	}, [id, props])

	const textArea = <textarea ref={ref} {...sharedProps}></textarea>
	const input = <input ref={ref} type={props.type} {...sharedProps}/>

	return (
		<div className="form-floating">
			{props.type === InputType.TEXTAREA ? textArea : input}
			{props.label && <label htmlFor={id}>{props.label}</label>}
			{props.optional && <span className="optional">volitelné</span>}
			<span className="form-error">{props.errors?.[props.name]?.message as string}</span>
		</div>
	)
})

export default TextInput