import {OrderData, OrderState, StopStates} from "~/src/Model/Order/Order";
import OrderTimeline from "~/src/Components/Order/Timeline/OrderTimeline";
import Map from "~/src/Components/Map/Map";
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";

interface Props {
    order: OrderData
    trackingCode: string
    onEstimatedTimeChangedHandler: (estimatedTime: number) => void
}

export default function MapSide(props: Props) {
    const [changeMapToCheckmark, setChangeMapToCheckmark] = useState<boolean>(false)

    useLayoutEffect(() => {
        if (StopStates.includes(props.order.state)) {
            setChangeMapToCheckmark(true)
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (StopStates.includes(props.order.state)) {
                setChangeMapToCheckmark(true)
            }
        }, 5000)

        return () => clearTimeout(timer)
    }, [props.order.state])

    const map = useCallback(() => {
        if (changeMapToCheckmark) {
            const crossSvg = <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill={props.order.branch.color} className="bi bi-x-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>
            const checkSvg = <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill={props.order.branch.color} className="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>
            const msg = () => {
                if (props.order.state === OrderState.DELIVERED) {
                    return "Objednávka doručena!"
                }
                if (props.order.state === OrderState.UNDELIVERED) {
                    return "Objednávka nedoručena!"
                }
                if (props.order.state === OrderState.CANCELED) {
                    return "Objednávka stornována!"
                }
                if (props.order.state === OrderState.ARRIVED) {
                    return "Kurýr je na místě"
                }
            }

            return (
                <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                    {[OrderState.DELIVERED, OrderState.ARRIVED].includes(props.order.state) ? checkSvg : crossSvg}
                    <h2 className="mt-3" style={{color: props.order.branch.color}}>{msg()}</h2>
                </div>
            )
        }

        return (
            <Map order={props.order} trackingCode={props.trackingCode!} onEstimatedTimeChange={props.onEstimatedTimeChangedHandler}/>
        )
    }, [changeMapToCheckmark, props.order, props.trackingCode])

    return (
        <>
            <div className="mt-5 mt-lg-0">
                <OrderTimeline orderState={props.order.state} activeColor={props.order.branch.color}/>
                {map()}
            </div>
        </>
    )
}