import OrderTimelineItem from '~/src/Components/Order/Timeline/OrderTimelineItem'
import {OrderState} from "~/src/Model/Order/Order";
import {useEffect, useState} from "react";

interface Props {
    orderState: OrderState,
    activeColor: string
}

type TimelineStates = OrderState.NEW | OrderState.PICKUP | OrderState.DELIVERING | OrderState.DELIVERED

const defaultState: { [key in TimelineStates]: 'done' | 'active' | 'none' } = {
    [OrderState.NEW]: 'active',
    [OrderState.PICKUP]: 'none',
    [OrderState.DELIVERING]: 'none',
    [OrderState.DELIVERED]: 'none'
}

export default function OrderTimeline(props: Props) {
    const [state, setState] = useState(defaultState)

    useEffect(() => {
        if (props.orderState === OrderState.NEW) {
            setState({
                [OrderState.NEW]: "active",
                [OrderState.PICKUP]: 'none',
                [OrderState.DELIVERING]: 'none',
                [OrderState.DELIVERED]: 'none'
            })
            return
        }
        if (props.orderState === OrderState.PICKUP) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'active',
                [OrderState.DELIVERING]: 'none',
                [OrderState.DELIVERED]: 'none'
            })
            return
        }
        if (props.orderState === OrderState.DELIVERING) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'done',
                [OrderState.DELIVERING]: 'active',
                [OrderState.DELIVERED]: 'none'
            })
            return
        }
        if (props.orderState === OrderState.ARRIVED) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'done',
                [OrderState.DELIVERING]: 'none',
                [OrderState.DELIVERED]: 'active'
            })
            return
        }
        if (props.orderState === OrderState.DELIVERED) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'done',
                [OrderState.DELIVERING]: 'done',
                [OrderState.DELIVERED]: 'active',
            })
        }
        if (props.orderState === OrderState.UNDELIVERED) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'done',
                [OrderState.DELIVERING]: 'done',
                [OrderState.DELIVERED]: 'active',
            })
        }
        if (props.orderState === OrderState.CANCELED) {
            setState({
                [OrderState.NEW]: "done",
                [OrderState.PICKUP]: 'done',
                [OrderState.DELIVERING]: 'done',
                [OrderState.DELIVERED]: 'active',
            })
        }
    }, [props.orderState])

    return (
        <div className="order-timeline">
            <OrderTimelineItem
                step={1}
                done={state.new === 'done'}
                active={state.new === 'active'}
                activeColor={props.activeColor}
            >
                Objednávka <br/>přijata
            </OrderTimelineItem>
            <OrderTimelineItem
                step={2}
                done={state.pickup === 'done'}
                active={state.pickup === 'active'}
                activeColor={props.activeColor}
            >
                Kurýr čeká na <br/>vyzvednutí
            </OrderTimelineItem>
            <OrderTimelineItem
                step={3}
                done={state.delivering === 'done'}
                active={state.delivering === 'active'}
                activeColor={props.activeColor}
            >Vezeme <br/>objednávku
            </OrderTimelineItem>
            <OrderTimelineItem
                step={4}
                done={state.delivered === 'done'}
                active={state.delivered === 'active'}
                activeColor={props.activeColor}
            >
                {props.orderState === OrderState.UNDELIVERED
                    ? 'Nedoručeno'
                    : props.orderState === OrderState.CANCELED
                    ? 'Stornováno'
                    : 'Doručeno'
                }
            </OrderTimelineItem>
        </div>
    )

}
