import { useMutation } from '@tanstack/react-query'
import { useOrderRepository } from '~/src/Infrastructure/Order/OrderRepository'
import { OrderEvaluationMutationParams, OrderEvaluationRequestData } from '~/src/Model/Order/OrderEvaluation'
import { AxiosError } from 'axios'

export const useOrderEvaluation = () => {
	const {sendEvaluation} = useOrderRepository()

	const {mutate, error, isSuccess} = useMutation<any, AxiosError, any, any>((request: OrderEvaluationMutationParams) =>
		sendEvaluation(request.trackingCode, request.request)
	)

	const send = (trackingCode: string, request: OrderEvaluationRequestData) => {
		mutate({trackingCode: trackingCode, request})
	}

	return {
		send,
		error,
		isSuccess
	}
}
