import TextInput from "~/src/Components/DesignSystem/Form/TextInput";
import {InputType} from "~/src/Model/Input/InputType";
import CustomButton from "~/src/Components/DesignSystem/Button/CustomButton";
import {RatingFormData} from "~/src/Model/Rating/RatingForm";
import {SubmitHandler, useForm} from "react-hook-form";
import {useState} from "react";

interface Inputs {
    note: string
}

interface Props {
    onSubmit: (formData: RatingFormData) => Promise<void>
    color: string
    submitDisabled: boolean
}

export default function RatingForm(props: Props) {
    const { register, handleSubmit } = useForm<Inputs>({
        mode: 'onBlur',
    })
    const [formData, setFormData] = useState<Inputs>({
        note: ''
    })

    const updateFormData = (event: any, inputName: keyof Inputs) => {
        const newData = {...formData}
        newData[inputName] = event.target.value
        setFormData(newData)
    }

    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
        await props.onSubmit(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
                name="note"
                label="Hodnocení"
                placeholder="Hodnocení"
                type={InputType.TEXT}
                optional
                register={register('note', {
                    onChange: (e) => updateFormData(e, 'note')
                })}
            />
            <div className="mt-10">
                <CustomButton submit className={"rating-button"} disabled={props.submitDisabled} color={props.color}>Odeslat</CustomButton>
            </div>
            {props.submitDisabled && <p className="rating-disabled-note" style={{color: props.color}}>Hodnocení lze odeslat až po doručení objednávky</p>}
        </form>
    )
}
