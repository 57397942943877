import { useOrderRepository } from '~/src/Infrastructure/Order/OrderRepository'
import {useQuery} from '@tanstack/react-query'
import {useState} from "react";
import {Order} from "~/src/Model/Order/Order";

export const useOrderFetch = (trackingCode: string) => {
	const {loadOrder} = useOrderRepository()
	const [orderNotFound, setOrderNotFound] = useState<boolean>(false)

	const { data: order, isLoading: loading, isSuccess: fetchCompleted, refetch } = useQuery<Order>(
		[trackingCode],
		async () => {
			const response = await loadOrder(trackingCode)

			return response.data
		},
		{
			retry: false,
			onError: () => {
				setOrderNotFound(true)
			},
			keepPreviousData: true
		}
	)

	return {
		fetch: refetch,
		order,
		loading,
		fetchCompleted,
		orderNotFound
	}
}
