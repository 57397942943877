import { ReactNode } from 'react'
import classNames from "classnames";

interface Props {
    step: number
    children: ReactNode
    active?: boolean
    done?: boolean
    activeColor: string
}

const defaultProps: Partial<Props> = {
    done: false,
    active: false
}

export default function OrderTimelineItem(props: Props) {
    props = {...defaultProps, ...props}
    return (
        <div className={classNames("order-timeline-item", { 'active': props.active, 'done': props.done })}>
            <div className="badge" style={props.active ? {background: props.activeColor} : {}}>{props.step}</div>
            <div className="desc" style={props.active ? {color: props.activeColor} : {}}>{props.children}</div>
        </div>
    )

}
