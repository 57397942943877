import { MapboxCoordinates, MapboxDirection } from '~/src/Model/Mapbox/Mapbox'
import { AxiosResponse } from 'axios'
import { useAxios } from '~/src/Infrastructure/Axios/Axios'

export const useMapBoxRepository = () => {
	const token = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN as string;
	const { $axios } = useAxios()

	return {
		getDirection: (start: MapboxCoordinates, end: MapboxCoordinates): Promise<AxiosResponse<MapboxDirection>> =>
			$axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${token}`)
	}
}
