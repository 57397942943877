import {OrderBranch} from "~/src/Model/Order/Order";

interface Props {
	orderBranch: OrderBranch
}

export default function Header(props: Props) {
	return (
		<div className="header-container">
			<div className="logo-container">
				<img className="img-fluid" src={props.orderBranch.logo_src} alt=""/>
			</div>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a className="go-back-link" style={{color: props.orderBranch.color}} href="https://www.prorozvoz.cz">Zpět na hlavní web</a>
		</div>
	)
}
