import {ReactNode} from "react";
import {Col, Container, Row} from "react-bootstrap";

interface Props {
    map: ReactNode
    order: ReactNode
}

export default function TrackingLayout(props: Props) {
    return (
        <Container>
            <Row>
                <Col lg={6}>
                    {props.order}
                </Col>
                <Col lg={6}>
                    {props.map}
                </Col>
            </Row>
        </Container>
    )
}