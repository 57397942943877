import axios from 'axios'
import { WebConfig } from '~/src/Model/WebConfig/WebConfig'
import { useContext } from 'react'
import { ConfigContext } from '~/src/Infrastructure/WebConfigContext/ConfigContext'

export const useAxiosWithoutContext = (apiUrl: string, apiKey: string) => {
	const $axios = axios.create({
		baseURL: apiUrl,
		headers: {
			'Authorization': apiKey
		}
	})

	return {
		$axios
	}
}

export const useAxios = () => {
	const { apiUrl, apiKey } = useContext<WebConfig>(ConfigContext)
	const { $axios } = useAxiosWithoutContext(apiUrl, apiKey)

	return {
		$axios
	}
}
