import Button from 'react-bootstrap/Button'
import { ReactNode } from 'react'
import { ButtonVariant } from 'react-bootstrap/types'

interface Props {
	children: ReactNode
	className?: string
	variant?: ButtonVariant
	submit?: boolean
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	color?: string
	disabled?: boolean
}

const defaultProps: Partial<Props> = {
	variant: 'primary',
	className: '',
	submit: false,
	color: '',
	disabled: false
}

export default function CustomButton(props: Props) {
	props = { ...defaultProps, ...props }

	return (
		<Button
			type={props.submit ? 'submit' : 'button'}
			variant={props.variant}
			disabled={props.disabled}
			className={props.className}
			style={{
				background: props.color,
				boxShadow: `0px 5px 30px ${props.color}33`,
				borderColor: props.color
			}}
			onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClick?.(e)}
		>
			{props.children}
		</Button>
	)
}